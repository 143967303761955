var rsa = require("node-rsa");
var fingerprint = require("ssh-fingerprint");

class Crypto
{

    // openssl genrsa -out certs/server/my-server.key.pem 2048
    // openssl rsa -in certs/server/my-server.key.pem -pubout -out certs/client/my-server.pub

    constructor(keyBytes, type='private')
    {
        this.key = new rsa();
        if(keyBytes === undefined)
        {
            this.key = new rsa({b: 512});
            this.key.generateKeyPair();
        }else{    
            this.key.importKey(keyBytes, type);
        }
        
        this.privateKey = this.key;
        this.publicKey = this.key
    }

    encrypt(message)
    {
        return this.publicKey.encrypt(message, 'base64', 'utf8');
    }

    decrypt(ciphertext)
    {
        return this.privateKey.decrypt(ciphertext, 'utf8');
    }

    getPublicKey()
    {
        return this.publicKey.exportKey("public").split('\n').slice(1).slice(0,-1).join('\n');
    }

    fingerprint()
    {
        return fingerprint(this.getPublicKey(), 'md5');
    }

    sign(message)
    {
        return this.privateKey.sign(message, 'base64', 'utf8' )
    }

    verifySignature(message, signature)
    {
        return this.publicKey.verify(message, signature, 'utf8', 'base64');
    }
}

export default Crypto;
