class ApiClient {

    static createRecord(user) {
        return new Promise((resolve, reject) => {
            const postOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user: user })
            }
            fetch(ApiClient.getApiEndpoint() + '/api/createRecord/', postOptions)
            .then(res => res.json())
            .then( results => {
                resolve(results);
            });
        });
    }

    static getGroupForKey(fingerprint) {
        return new Promise((resolve, reject) => {
            fetch(ApiClient.getApiEndpoint() + '/api/getGroupForKey/' + fingerprint)
            .then(res => res.json())
            .then( results => {
                resolve(results);
            });
        });
    }

    static getRecordsInGroup(groupNumber)
    {
        return new Promise((resolve, reject) => {
            fetch(ApiClient.getApiEndpoint() + '/api/getRecordsInGroup/' + groupNumber)
                .then(res => res.json())
                .then( results => {
                    resolve(results);
                });
        });
    }

    static allowUser(fingerprint) {
        return new Promise((resolve, reject) => {
            const postOptions = {
                method: 'POST'
            }
            fetch(ApiClient.getApiEndpoint() + '/api/allowUser/' + fingerprint, postOptions)
                .then(() => {
                    resolve();
                });
        });
    }

    static prohibitUser(fingerprint, denialInformation) {
        return new Promise((resolve, reject) => {
            const postOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ denialInformation: denialInformation })
            }
            fetch(ApiClient.getApiEndpoint() + '/api/prohibitUser/' + fingerprint, postOptions)
                .then(() => {
                    resolve();
                });
        });
    }

    static getLogs() {
        return new Promise((resolve, reject) => {
            fetch(ApiClient.getApiEndpoint() + '/api/getLogs')
            .then(res => res.json())
            .then( results => {
                resolve(results);
            });
        });
    }

    static getApiEndpoint() {
        if (process.env.NODE_ENV === "production")
        {
            return "https://nicks.stewart.haus";
        }
        else
        {
            return "http://localhost";
        }
    }

}

export default ApiClient;
