class Gun {

    constructor(type)
    {
        this.type = type;
        this.serialNumber = serialNumberCounter++;
        this.history=[];
    }

    getImagePath()
    {
        return "./collateral/images/guns/" + (this.type) + ".jpg"
    }
}

var serialNumberCounter = 0;

const GunType = {
    Pistol : "pistol",
    AK47 : "ak47",
    AR15 : "ar15",
    Revolver : "revolver"
}

export {Gun, GunType};