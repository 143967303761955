import Crypto from "./crypto";

class WebUser {
    constructor(name, idNumber, prohibited = false, guns = [], comment = undefined, denialInformation = undefined, imagePath = undefined) {
        this.name = name;
        this.idNumber = idNumber;
        this.comment = comment;
        this.prohibited = prohibited;
        this.denialInformation = denialInformation;
        this.guns = guns;
        if (imagePath === undefined)
        {
            this.imagePath = "./collateral/images/id" + (this.idNumber % 8) + ".png"
        }
        this.saleRecords = [];
    }

    addKeyManagement(keyBytes = undefined, type='private') {
        this.keyManagement = new Crypto(keyBytes, type);
        this.publicKey = this.keyManagement.getPublicKey();
        this.fingerprint = this.keyManagement.fingerprint();
    }

    prepareForRequest()
    {
        this.keyManagement = undefined;
    }

    crypto()
    {
        if(this.keyManagement === undefined)
        {
            this.keyManagement = new Crypto(this.publicKey, 'public'); 
        }
        return this.keyManagement;
    }

    addGun(gun)
    {
        this.guns.push(gun);
    }

    hasGun(gun)
    {
        return this.guns.find(x => {return x.serialNumber === gun.serialNumber;}) !== undefined;
    }

    removeGun(gun, saleRecord)
    {
        const index = this.guns.indexOf(gun);
        if(index > -1) {
            this.guns.splice(index, 1);
            if(saleRecord)
            {
                this.saleRecords.push({item:{type:gun.type, serialNumber:gun.serialNumber}, record:saleRecord});
            }
        }
    }
}

export default WebUser;