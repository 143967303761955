import React from "react";
import UserListEntry from "./UserListEntry";
import { GunList } from "./GunList";
import ApiClient from "../model/ApiClient";

class UserManager extends React.Component {

    constructor() {
        super();
        this.performSelfCheck = this.performSelfCheck.bind(this);
        this.state = { fullUserData: undefined };
    }
    render() {
        let user = this.props.user;
        if (user === undefined)
        {
            return null;
        }

        let gunList = <GunList guns={this.props.user.guns} />;
        if(this.props.user.guns.length === 0)
        {
            gunList = <p>You have no guns.</p>
        }
        
        let selfCheck = <button className="selectUserButton" onClick={() => { this.performSelfCheck() }}>Check My Records</button>
        if(user.group === undefined)
        {
            selfCheck = <button className="selectUserButton" onClick={() => {this.lookupGroup()}}>Lookup My Group Number</button>
        }

        if (this.state.fullUserData && JSON.parse(this.state.fullUserData.clearData).idNumber === this.props.user.idNumber)
        {
            let fullData = this.state.fullUserData;
            let clearData = JSON.parse(fullData.clearData);
            let denialText;
            if (clearData.prohibited)
            {
                denialText = <p>You have been denied due to <b>{fullData.denialInformation}</b></p>
            }

            selfCheck = <div>
                <p>Your record was last updated on {clearData.date}</p>
                <p>You are {clearData.prohibited ? "PROHIBITED from purchasing" : "allowed to purchase"} firearms</p>
                {denialText}
            </div>
        }

        return <div id="userManager">
            <div className="centerHorizontal">
                <a className="closebtn" onClick={() => { this.props.closeUserManager() }}>&times;</a>
                <h2 className="centerText">User Details</h2>
                <UserListEntry user={user} />
                <h4>Your Guns:</h4>
                {gunList}
                <p>You are in group: {user.group ?? "???"}</p>
                {selfCheck}
            </div>
        </div>;
    }

    performSelfCheck() {
        ApiClient.getRecordsInGroup(this.props.user.group)
            .then(records => {
                var record = records.find(x => {
                    return x.fingerprint === this.props.user.fingerprint;
                });
                if (record === undefined)
                { return }

                var fullDataString = this.props.user.crypto().decrypt(record.encryptedData);
                this.setState({ fullUserData: JSON.parse(fullDataString) });
            });
    }

    lookupGroup()
    {
        ApiClient.getGroupForKey(this.props.user.fingerprint)
            .then(groupNumber => {
                this.props.user.group = groupNumber;
                this.setState({update:true});
            });
    }

}

export default UserManager;