import React from "react";
import "./TransactionUI.css";
import UserListEntry from "./UserListEntry";
import IDCard from "./IDCard";
import { GunList } from "./GunList";
import { TransactionState } from "../model/Transaction";

class TransactionUI extends React.Component {

    constructor() {
        super();
        this.state = {};
        this.startTransaction = this.startTransaction.bind(this);
    }

    render() {
        let transaction = this.props.transaction;

        let transactionStateUI = null;
        let transactionStartButton = null;
        let buyerRecordUI = null;
        let signature = null;
        let prohibitedUI = null;
        let continueButton = null;
        let denyButton = null;
        let gunSelect = undefined;
        let idCheckOverlay = undefined;

        let validatedBuyerData = undefined;
        let prohibited = undefined;

        transactionStartButton = <button className="selectUserButton" onClick={() => { transaction.resetTransaction() }}>Reset Transaction</button>;

        switch (transaction.state)
        {
            case TransactionState.UnknownGroup:
                buyerRecordUI = <p>Need to look up Buyer's group</p>;
                transactionStartButton = <button className="goButton" onClick={() => transaction.findBuyersGroup()}>Lookup Group</button>
                break;
            case TransactionState.Ready:
                buyerRecordUI = <p>Ready to look up Buyer Record</p>;
                transactionStartButton = <button className="goButton" onClick={this.startTransaction}>Start Transaction</button>;
                break;
            case TransactionState.FindingBuyerGroup:
                buyerRecordUI = <p>Fetching Buyer's Group...</p>;
                break;
            case TransactionState.FindingBuyerRecord:
                buyerRecordUI = <p>Fetching Buyer Records...</p>;
                break;
            case TransactionState.BuyerRecordNotFound:
                buyerRecordUI = <p>No Records Found for this Buyer!</p>;
                continueButton = <p><b>DO NOT complete this sale - user is NOT FOUND</b></p>
                denyButton = <button className="stopButton" onClick={() => transaction.resetTransaction()}>OK</button>
                break;
            case TransactionState.BuyerRecordInvalid:
                buyerRecordUI = <p>Record Found - Record is <b>out of date and invalid</b></p>;
                continueButton = <p><b>DO NOT complete this sale</b></p>
                denyButton = <button className="stopButton" onClick={() => transaction.resetTransaction()}>OK</button>
                break;
            case TransactionState.FoundBuyerRecord:
                buyerRecordUI = <p>Record Found</p>;
                break;
            case TransactionState.DecryptyingBuyerRecord:
                buyerRecordUI = <p>Record Found - decrypting</p>;
                break;
            case TransactionState.ValidatingBuyerRecord:
                buyerRecordUI = <p>Record Found - validating</p>;
                break;
            case TransactionState.SellerMustCheckID:
                validatedBuyerData = transaction.getValidatedBuyerInfo();
                prohibited = validatedBuyerData.prohibited;
                buyerRecordUI = <p>Record Validated!</p>;
                if (prohibited)
                {
                    continueButton = <p><b>DO NOT complete this sale - user is PROHIBITED</b></p>
                    denyButton = <button className="stopButton" onClick={() => transaction.resetTransaction()}>OK</button>
                }
                else
                {
                    this.props.createOverlay("overlay", IdCheckOverlay, { transaction: transaction, serverID: validatedBuyerData });
                }
                break;
            case TransactionState.BuyerIDInvalid:
                validatedBuyerData = transaction.getValidatedBuyerInfo();
                buyerRecordUI = <p>Record Found and Validated!</p>;
                continueButton = <p><b>DO NOT complete this sale - IDs do not match</b></p>
                denyButton = <button className="stopButton" onClick={() => transaction.resetTransaction()}>OK</button>
                break;
            case TransactionState.Proceed:
                validatedBuyerData = transaction.getValidatedBuyerInfo();
                buyerRecordUI = <p>Record Found and Validated!</p>;
                continueButton = <p>Click the gun you want to sell</p>
                gunSelect = transaction.setGun;
                break;
            case TransactionState.CompletingTransaction:
                validatedBuyerData = transaction.getValidatedBuyerInfo();
                buyerRecordUI = <p>Record Found and Validated!</p>;
                continueButton = <p>Completing the sale...</p>
                break;
            case TransactionState.Complete:
                validatedBuyerData = transaction.getValidatedBuyerInfo();
                buyerRecordUI = <p>Record Found and Validated!</p>;
                continueButton = <p>Sale Complete!</p>
                denyButton = <button className="goButton" onClick={() => transaction.resetTransaction()}>OK</button>
                break;
            default:
                break;
        }

        transactionStateUI = <div className="transactionUI container"></div>

        if (transaction.state !== TransactionState.NotStarted)
        {
            transactionStateUI =
                <div className="transactionUI container">
                    {transactionStartButton}
                    <p>Buyer's group number is <b>{transaction.buyer?.group ?? "unknown"}</b></p>
                    <p>Buyer's Public Key: <b>{transaction.buyer?.crypto()?.fingerprint().substr(0, 11) ?? "unknown"}</b></p>
                    {buyerRecordUI}
                    {prohibitedUI}
                    {signature}
                    {continueButton}
                    {denyButton}
                </div>
        }


        return (
            <div className="Transaction">
                <SellerUI seller={transaction.seller} transactionRecord={transaction.getSaleRecords()} gunSelect={gunSelect} userSelect={this.props.openUserMenu} />
                <BuyerUI sellerExists={transaction.seller !== undefined}
                    buyer={transaction.buyer}
                    transactionRecord={transaction.getSaleRecords()}
                    userSelect={this.props.openUserMenu} />
                {transactionStateUI}
            </div>
        );
    }

    startTransaction() {
        this.props.transaction.startTransaction();
    }
}

class IdCheckOverlay extends React.Component {
    render() {
        let transaction = this.props.transaction;
        return <div className="centerOverlay">
            <div>
                <div className="container">
                    <h1>Do These IDs Match?</h1>
                    <div className="idRow">
                        <h4 className="idLabel">User's ID:</h4>
                        <div className="idImage">
                            <IDCard user={transaction.buyer} />
                        </div>

                    </div>
                    <div className="idRow">
                        <h4 className="idLabel">ID from server:</h4>
                        <div className="idImage">
                            <IDCard user={this.props.serverID} />
                        </div>
                    </div>
                    <button className="goButton wide" onClick={() => {
                        document.getElementById("overlay").style.display = "none";
                        transaction.setIDValidated(true)
                    }}>IDs Match</button>
                    <button className="stopButton wide" onClick={() => {
                        document.getElementById("overlay").style.display = "none";
                        transaction.setIDValidated(false)
                    }}>IDs Do Not Match!</button>
                </div>
            </div>
        </div>
    }
}

class SellerUI extends React.Component {
    render() {
        let selectSellerButton = null;
        if (this.props.seller === undefined)
        {
            selectSellerButton = <h2 className="selectUserButton" onClick={() => { this.props.userSelect() }}>Select Seller</h2>;
        }
        let gunList = null;
        if (this.props.gunSelect)
        {
            gunList = <GunList guns={this.props.seller?.guns} gunSelect={this.props.gunSelect} />
        }

        return <div id="seller" className="column container">
            <div className="centerHorizontal">
                <h2 className="centerText">Seller</h2>
                {selectSellerButton}
                <UserListEntry user={this.props.seller} />
                {gunList}
                <TransactionRecordUI transactionRecord={this.props.transactionRecord} wasSeller={true} /></div>
        </div>;
    }
}

class TransactionRecordUI extends React.Component {
    render() {
        if (this.props.transactionRecord)
        {
            var date = this.props.transactionRecord.saleDetails.date;
            var item = this.props.transactionRecord.saleDetails.item;

            var itemDescription = item.type + " #" + item.serialNumber;

            let summary;
            if (this.props.wasSeller)
            {
                summary = <p>Sold {itemDescription} to <b>{this.props.transactionRecord.buyerFingerprint.substr(0,11)}</b> at {date}</p>;
            }
            else
            {
                summary = <p>Bought {itemDescription} on {date} from <b>{this.props.transactionRecord.sellerFingerprint.substr(0,11)}</b></p>;
            }

            return <div id="transactionRecord">
                {summary}
            </div>;
        }
        return null;
    }
}

class BuyerUI extends React.Component {
    render() {

        let buyerText;
        let buyerButton;
        if (this.props.sellerExists)
        {
            buyerText = <h2 className="centerText">Buyer</h2>;;
        }
        if (this.props.sellerExists && this.props.buyer === undefined)
        {
            buyerButton = <h2 className="selectUserButton" onClick={() => { this.props.userSelect() }}>Select Buyer</h2>;
        }

        return <div id="buyer" className="column container">
            <div className="centerHorizontal">
                {buyerText}
                {buyerButton}
                <UserListEntry user={this.props.buyer} />
                <TransactionRecordUI transactionRecord={this.props.transactionRecord} />
            </div>
        </div>;
    }
}



export default TransactionUI;