import React from "react";

class About extends React.Component {
    render() {        
        return <div className="aboutOverlay">
            <h1>About NICKS</h1>
            <p>NICKS is a system designed to replace our current gun background check system (<a href="https://www.fbi.gov/services/cjis/nics">NICS</a>) with a free-at-point-of-use system that everyone can use.  NICKS (National Instant Criminal (Key-Based) Background Check System) leverages public key cryptography to allow everyone to run gun background checks securely - without creating a registry of guns, gun owners, or any mapping between the two.</p>
            <p>A <a href="https://stewart.haus/assets/files/references/NICKS_Whitepaper.pdf">white paper</a> I wrote goes into detail about how NICKS works and how it differs from the system we have today.</p>
            <p>This page is a work in progress but it does demonstrate the basic principles - In the transaction of a Seller (Alice) selling a firearm to a Buyer (Bob) using NICKS:</p>
            <ol>
                <li>This system <b><u>must</u></b> allow Alice to verify that Bob is not a prohibited purchaser no matter who Alice is (i.e., even if Alice is not an FFL)</li>
                <li>No internet traffic from this system should result in being able to prove beyond reasonable doubt that Alice or Bob engaged in the sale of a firearm. </li>
                <li>The ledger <b><u>must not</u></b> include identifying information in the clear, such that Proceed / Do Not Proceed information cannot be obtained about Bob without Bob’s permission.</li>
                <li>Even with Bob's permission, Alice should <b><u>only</u></b> get Proceed / Do Not Proceed information about the sale of a firearm, not any reason for the denial.</li>
                <li>Bob should <b><u>never</u></b> need any of Alice's information to complete the sale. (Though she may provide it)</li>
                <li>A transaction should result in records for Alice that positively identify that Alice performed the check on Bob, and Bob is who he says he is.  </li>
            </ol>
            <p>Additionally, there are several goals about the use of this system outside of a sale:</p>
            <ol start="7">
                <li>Inclusion on the public ledger used to provide Proceed / Do Not Proceed information <b><u>must not</u></b> be proof that you own a gun. (i.e. the ledger can't be a registry).</li>
                <li>The owner of the system must be able to mark a user as a prohibited user and have that prohibition reflected <b><u>instantly</u></b>.</li>
                <li>A user who has been prohibited must be able to discover why they have been prohibited – <b><u>instantly</u></b>.</li>
                <li>Using this system must be easy to understand and background checks should be easy to run between any buyer and seller with a smart phone or computer.</li>
            </ol>
        </div>
    }
}

export default About;