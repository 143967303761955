import React from "react";

class LogUI extends React.Component {
    render() {
        const logs = this.props.logEntries;
        let serverLogEntries = null;

        if (logs !== undefined)
        {
            const logArray = [];
            logs.forEach(log => {
                if (log.date > this.props.logsAfter && log.context.entry !== "/api/getLogs")
                {
                    logArray.push((<LogEntry log={log} />))
                }
            });

            serverLogEntries = <div>
                {logArray}
            </div>;

        }

        return (
            <div className="serverLogs">
                <div className="logTitleBar">
                    <h4>Server Logs</h4>
                </div>
                <div id="logContainer" className="logField">
                    {serverLogEntries}
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        const objDiv = document.getElementById('logContainer');
        objDiv.scrollTop = objDiv.scrollHeight;
      }
}

class LogEntry extends React.Component {
    render() {
        const log = this.props.log;

        var message = log.context.ip + " called " + log.context.entry + " at " + new Date(log.date).toLocaleString('en-US') + ": " + log.message;

        return <p>{message}</p>
    }
}

export default LogUI;