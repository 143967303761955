import React from "react";

class GunList extends React.Component {
    render() {
        const guns = this.props.guns;

        if(guns === undefined)
        {
            return null;
        }

        const style = {
            "width": "max-content"
        };

        const gunArray = [];
        guns.forEach(gun => {
            gunArray.push((<GunUI gun={gun} gunSelect = {this.props.gunSelect} />))
        });

        return (
            <div style={style}>
                <div id="gunList">
                    {gunArray}
                </div>
            </div>
        );
    }
}

class GunUI extends React.Component {
    render() {

        if (this.props.gun === undefined)
        {
            return null;
        }

        var gun = this.props.gun;

        const imagePath = gun.getImagePath();
        this.setCurrentGun = this.setCurrentGun.bind(this);
        return (
            <div className="gun" onClick={this.setCurrentGun}>
                <img src={imagePath} alt="User" width="100px" />
                <h2>{gun.type}</h2>
                <p>Serial Number: {gun.serialNumber}</p>
            </div>
        );
    }

    setCurrentGun() {
        if (this.props.gunSelect !== undefined)
        {
            this.props.gunSelect(this.props.gun);
        }
    }
}

export  {GunList, GunUI};