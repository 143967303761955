import React from "react";
import "./UserListEntry.css";

class UserListEntry extends React.Component {
    render() {

        if (this.props.user === undefined)
        {
            return null;
        }

        const user = this.props.user;
        if (user.fingerprint === undefined)
        {
            user.fingerprint = "";
        }
        let comment = null;
        if (user.comment !== undefined)
        {
            comment = <p>{user.comment}</p>;
        }
        
        const imagePath = "./collateral/images/id" + (user.idNumber % 8) + ".png";
        this.setCurrentUser = this.setCurrentUser.bind(this);
        return (
            <div className="UserListEntry" onClick={this.setCurrentUser}>
                <img src={imagePath} alt="User" />
                <h2>{user.name}</h2>
                <p>{user.guns.length + " gun" + ((user.guns.length !== 1) ? "s": "")}</p>
                {comment}
            </div>
        );
    }

    setCurrentUser() {
        if (this.props.setCurrentUser !== undefined)
        {
            this.props.setCurrentUser(this.props.user);
        }
    }


}

export default UserListEntry;