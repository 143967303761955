import React from "react";
import UserListEntry from "./UserListEntry";

class UserList extends React.Component {
    render() {
        const users = this.props.users;
        const userArray = [];
        users.forEach(user => {
            userArray.push((<UserListEntry key={user.name} user={user} setCurrentUser={this.props.setCurrentUser} />))
        });

        return (
            <div>
                <h2>Known Users</h2>
                <div id="userContainer">
                    {userArray}
                </div>
            </div>
        );
    }
}

export default UserList;