import WebUser from "./webUser";
import Crypto from "./crypto";
import ApiClient from "./ApiClient";

class Transaction {

    constructor(updateStateEvent) {
        this.updateStateEvent = updateStateEvent.bind(this);
        this.setGun = this.setGun.bind(this);
        this.setDefaultState();
    }

    setDefaultState() {
        this.buyer = undefined;
        this.seller = undefined;
        this.state = TransactionState.NotStarted;
        this.rawBuyerRecord = undefined;
        this.buyerRecord = undefined;
        this.saleRecords = undefined;
        this.gun = undefined;
    }

    sleep = () => {
        return new Promise(resolve => setTimeout(resolve, this.stepDelay))
    }

    resetTransaction() {
        this.setDefaultState();
        this.updateStateEvent(this);
    }

    setStepDelay(milliseconds)
    {
        this.stepDelay = milliseconds;
    }

    checkForReady() {
        if (this.state === TransactionState.NotStarted)
        {
            if (this.buyer && this.seller)
            {
                if (this.buyer.group !== undefined)
                {
                    this.changeState(TransactionState.Ready);
                }
                else
                {
                    this.changeState(TransactionState.UnknownGroup);
                }
            }
        }
    }

    findBuyersGroup() {
        if (this.state !== TransactionState.UnknownGroup)
        {
            return;
        }

        this.changeState(TransactionState.FindingBuyerGroup);
        ApiClient.getGroupForKey(this.buyer.crypto().fingerprint()).then(groupNumber => {
            this.buyer.group = groupNumber;
            this.sleep().then(() => {
                this.changeState(TransactionState.Ready);
            });
        });
    }

    startTransaction() {
        if (this.state === TransactionState.Ready)
        {
            this.changeState(TransactionState.FindingBuyerRecord);
            this.sleep().then(() => {

                if (this.ledgerKey === undefined)
                {
                    fetch('./collateral/ledgerKey', {
                        method: "get"
                    })
                        .then(response => response.text())
                        .then(keyBytes => {
                            this.ledgerKey = new Crypto(keyBytes, 'public');
                            this.fetchBuyerRecord(this.buyer.group, this.buyer.crypto().fingerprint());
                        });
                }
                else
                {
                    this.fetchBuyerRecord(this.buyer.group, this.buyer.fingerprint);
                }
            });
        }
    }

    fetchBuyerRecord(group, fingerprint) {
        ApiClient.getRecordsInGroup(group).then(records => {
            var buyerRecord = records.find(x => {
                return x.fingerprint === fingerprint;
            });
            if (buyerRecord === undefined)
            {
                this.changeState(TransactionState.BuyerRecordNotFound);
                return;
            }

            this.changeState(TransactionState.FoundBuyerRecord);
            this.sleep().then(() => {
                this.rawBuyerRecord = buyerRecord;
                this.decryptBuyerRecord(buyerRecord);
            });
        });
    }

    decryptBuyerRecord(record) {
        if (this.state !== TransactionState.FoundBuyerRecord)
        {
            return;
        }

        this.changeState(TransactionState.DecryptyingBuyerRecord);
        this.sleep().then(() => {
            var fullDataString = this.buyer.crypto().decrypt(record.encryptedData);
            var fullData = JSON.parse(fullDataString);
            var clearDataString = fullData.clearData;
            this.changeState(TransactionState.ValidatingBuyerRecord);
            this.sleep().then(() => {
                var validClearSignature = this.ledgerKey.verifySignature(clearDataString, record.signedClearData);
                if (!validClearSignature)
                {
                    this.changeState(TransactionState.BuyerRecordInvalid);
                    return;
                }

                this.buyerClearDataString = clearDataString;
                var buyerClearData = JSON.parse(clearDataString);
                this.buyerRecord = new WebUser(buyerClearData.name, buyerClearData.idNumber, buyerClearData.prohibited);
                this.buyerRecord.addKeyManagement(this.buyer.crypto().getPublicKey(), 'public');
                this.buyerRecord.date = buyerClearData.date;
                this.changeState(TransactionState.SellerMustCheckID);
            });
        });
    }

    getValidatedBuyerInfo() {
        if (this.state !== TransactionState.SellerMustCheckID
            && this.state !== TransactionState.BuyerIDInvalid
            && this.state !== TransactionState.Proceed
            && this.state !== TransactionState.CompletingTransaction
            && this.state !== TransactionState.Complete)
        {
            return null;
        }

        return this.buyerRecord;
    }

    setIDValidated(validated) {
        if (this.state !== TransactionState.SellerMustCheckID)
        {
            return;
        }

        if (validated)
        {
            this.changeState(TransactionState.Proceed);
        }
        else
        {
            this.changeState(TransactionState.BuyerIDInvalid);
        }
    }

    setGun(gun) {
        if (this.state !== TransactionState.Proceed || this.gun !== undefined)
        {
            return;
        }

        this.gun = gun;
        this.completeTransaction();
    }

    completeTransaction() {
        if (!this.state.proceed)
        {
            return;
        }

        var dateString = new Date(Date.now()).toLocaleDateString('en-us');
        var saleDetails = { date: dateString, item: { type: this.gun.type, serialNumber: this.gun.serialNumber } };

        this.changeState(TransactionState.CompletingTransaction);

        var sellerSignature = this.seller.crypto().sign(saleDetails);
        var buyerSignature = this.buyer.crypto().sign(saleDetails);

        var saleRecord = {
            buyerClearData: this.ledgerKey.encrypt(this.buyerClearDataString),
            buyerFingerprint: this.buyer.fingerprint,
            ledgerClearDataSignature: this.rawBuyerRecord.signedClearData,
            saleDetails: saleDetails,
            sellerFingerprint: this.seller.fingerprint,
            sellerSignature: sellerSignature,
            buyerSignature: buyerSignature
        };

        this.gun.history.unshift(saleRecord);
        this.saleRecords = saleRecord;

        this.seller.removeGun(this.gun, saleRecord);
        this.buyer.addGun(this.gun);

        this.changeState(TransactionState.Complete);
    }

    getSaleRecords() {
        if (this.state !== TransactionState.Complete)
        {
            return null;
        }

        return this.saleRecords;
    }

    changeState(newState) {
        this.state = newState;
        this.updateStateEvent(this);
    }

}

const TransactionState = {
    NotStarted: { name: "notStarted", proceed: false },
    UnknownGroup: { name: "unknownGroup", proceed: false },
    FindingBuyerGroup: { name: "findingGroup", proceed: false },
    Ready: { name: "ready", proceed: false },
    FindingBuyerRecord: { name: "findingBuyerRecord", proceed: false },
    FoundBuyerRecord: { name: "foundBuyerRecord", proceed: false },
    BuyerRecordNotFound: { name: "buyerRecordNotFound", proceed: false },
    DecryptyingBuyerRecord: { name: "decryptingBuyerRecord", proceed: false },
    ValidatingBuyerRecord: { name: "validatingBuyerRecord", proceed: false },
    BuyerRecordInvalid: { name: "buyerRecordInvalid", proceed: false },
    SellerMustCheckID: { name: "sellerMustCheckID", proceed: false },
    Proceed: { name: "proceed", proceed: true },
    BuyerIDInvalid: { name: "buyerIDInvalied", proceed: false },
    CompletingTransaction: { name: "completingTransaction", proceed: true },
    Complete: { name: "transactionComplete", proceed: true },
}

export { Transaction, TransactionState };