import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import UserList from './ui-components/UserList';
import TransactionUI from './ui-components/TransactionUI';
import WebUser from './model/webUser';
import { Transaction } from './model/Transaction';
import { Gun, GunType } from './model/gun';
import LogUI from './ui-components/LogUI';
import About from './ui-components/About';
import UserManager from './ui-components/UserManager';
import ApiClient from './model/ApiClient';

const SelectUserAction =
{
  SelectSeller: "selectSeller",
  SelectBuyer: "selectBuyer",
  Prohibit: "prohibitUser",
  Allow: "allowUser",
  ViewDetails: "viewDetails",
  None: "none"
}

class NicksUI extends React.Component {
  constructor() {
    super();
    this.setCurrentUser = this.setCurrentUser.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
    this.updateLogs = this.updateLogs.bind(this);
    this.handleUpdates = this.handleUpdates.bind(this);
    this.handleTransactionUpdates = this.handleTransactionUpdates.bind(this);
    this.handleLogUpdates = this.handleLogUpdates.bind(this);
    this.toggleServerLogs = this.toggleServerLogs.bind(this);
    this.toggleStepDelay = this.toggleStepDelay.bind(this);
    this.closeUserManager = this.closeUserManager.bind(this);

    this.state = {
      users: [
        new WebUser("Eric", 0, false, [new Gun(GunType.AR15), new Gun(GunType.Pistol), new Gun(GunType.Pistol)]),
        new WebUser("Nate", 1, false, [new Gun(GunType.Revolver)]),
        new WebUser("Tyson", 2, false, [new Gun(GunType.AK47), new Gun(GunType.AR15)]),
        new WebUser("Susan", 3, false, [new Gun(GunType.Pistol)]),
        new WebUser("Scammer", 4, false, [], "Fake ID"),
        new WebUser("Clueless", 5, false, [new Gun(GunType.AR15)], "Doesn't Know Group"),
        new WebUser("Hacker", 6, false, [], "Not in system"),
        new WebUser("BadGuy", 7, true, [], "Prohibited", "Armed Robbery Conviction")
      ],
      transaction: new Transaction(this.handleTransactionUpdates)
    }

    this.state.selectUserAction = SelectUserAction.SelectSeller;
    this.state.showServerLogs = false;
    this.state.enableStepDelay = true;
    this.state.transaction.setStepDelay(1000);

    this.state.users.forEach(user => {
      fetch('./collateral/' + user.name + '.pem', {
        method: "get",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.text())
        .then(keyBytes => {
          user.addKeyManagement(keyBytes);

          if (user.name !== "Hacker")
          {
            var requestUser = new WebUser(user.name, (user.name === "Scammer" ? user.idNumber + 1 : user.idNumber), user.prohibited, undefined, undefined, user.denialInformation);
            requestUser.addKeyManagement(keyBytes);
            requestUser.prepareForRequest();

            ApiClient.createRecord(requestUser).then(group => {
              if (user.name === "Clueless")
              {
                user.group = undefined;
              }
              else
              {
                user.group = group;
              }
              this.handleUpdates(this.state.users);
              this.state.logsOnlyAfter = Date.now();
            });

          }
          if (user.name === "Hacker")
          {
            user.group = 0;
          }
        });
    });

    this.updateLogs();
  }

  handleUpdates(users) {
    this.setState({ users: users });
  }

  handleTransactionUpdates(transaction) {
    let newSelectUserAction = this.state.selectUserAction;
    if (transaction.seller === undefined)
    {
      newSelectUserAction = SelectUserAction.SelectSeller;
    }
    else if (transaction.buyer === undefined)
    {
      newSelectUserAction = SelectUserAction.SelectBuyer;
    }
    this.setState({ transaction: transaction, selectUserAction: newSelectUserAction });
    this.updateLogs();
  }

  updateLogs(override = false) {
    if (override || this.state.showServerLogs)
    {
      ApiClient.getLogs().then(logs => {
        this.handleLogUpdates(logs);
      });
    }
  }

  handleLogUpdates(log) {
    this.setState({ log: log });
  }

  toggleServerLogs() {
    var showServerLogs = !this.state.showServerLogs;
    this.setState({ showServerLogs: showServerLogs });
    this.updateLogs(true);
  }

  toggleStepDelay() {
    var enableStepDelay = !this.state.enableStepDelay;
    var stepDelay = enableStepDelay ? 1000 : 0;
    this.state.transaction.setStepDelay(stepDelay);
    this.setState({ enableStepDelay: enableStepDelay });
  }

  setCurrentUser(user) {
    closeUserSelectDrawer();
    let newSelectUserAction = this.state.selectUserAction;
    let transaction = this.state.transaction;
    switch (this.state.selectUserAction)
    {
      case SelectUserAction.SelectSeller:
        transaction.seller = user;
        transaction.checkForReady();
        newSelectUserAction = SelectUserAction.SelectBuyer;
        break;
      case SelectUserAction.SelectBuyer:
        transaction.buyer = user;
        transaction.checkForReady();
        newSelectUserAction = SelectUserAction.None;
        break;
      case SelectUserAction.Prohibit:
        this.prohibitUser(user, prompt("Why is this user prohibited?"));
        this.resetTransaction();
        newSelectUserAction = SelectUserAction.SelectSeller;
        break;
      case SelectUserAction.Allow:
        this.allowUser(user);
        this.resetTransaction();
        newSelectUserAction = SelectUserAction.SelectSeller;
        break;
      case SelectUserAction.ViewDetails:
        this.setState({ showUserDetails: user });
        break;
      default:
        break;
    }

    this.setState({ transaction: transaction, selectUserAction: newSelectUserAction });
  }

  closeUserManager() {
    this.setState({ showUserDetails: undefined });
    this.resetTransaction();
  }

  resetTransaction() {
    this.state.transaction.resetTransaction();
  }

  createNewUser(userName) {
    if (userName === undefined)
    {
      return;
    }
    var newUser = new WebUser(userName, Math.floor((Math.random() * 100) + 8), false);
    newUser.addKeyManagement();

    var requestUser = { ...newUser };
    requestUser.keyManagement = undefined;

    ApiClient.createRecord(requestUser).then(group => {
      newUser.group = group;
      this.handleUpdates(this.state.users);
      this.updateLogs();
    });

    this.state.users.push(newUser);
    this.handleUpdates(this.state.users);
  }

  prohibitUser(user, denialInformation) {
    ApiClient.prohibitUser(user.fingerprint, denialInformation).then(() => {
      this.updateLogs();
    });
  }

  allowUser(user) {
    ApiClient.allowUser(user.fingerprint).then(() => {
      this.updateLogs();
    });
  }

  render() {
    const users = this.state.users;
    let serverLogsUI;
    if (this.state.showServerLogs)
    {
      serverLogsUI = <div className="serverLogs">
        <LogUI logEntries={this.state.log} logsAfter={this.state.logsOnlyAfter} />
      </div>
    }

    let transactionUI = <TransactionUI transaction={this.state.transaction} openUserMenu={openUserSelectDrawer} createOverlay={createOverlay} />;
    if (this.state.showUserDetails !== undefined)
    {
      transactionUI = <UserManager user={this.state.showUserDetails} closeUserManager={this.closeUserManager} />
    }

    return (
      <div className="app-frame">
        <header className="navbar">
          <a className="navTitle">NICKS</a>
          <div className="dropdown">
            <button className="dropbtn">Users
              <i className="fa fa-caret-down"></i>
            </button>
            <div className="dropdown-content">
              <a onClick={() => openUserSelectDrawer()}>Show Users</a>
              <a onClick={() => this.createNewUser(prompt("User Name?"))}>Create New User</a>
              <a onClick={() => { this.setState({ selectUserAction: SelectUserAction.Prohibit }); openUserSelectDrawer(); }}>Prohibit User</a>
              <a onClick={() => { this.setState({ selectUserAction: SelectUserAction.Allow }); openUserSelectDrawer(); }}>Allow User</a>
              <a onClick={() => { this.setState({ selectUserAction: SelectUserAction.ViewDetails }); openUserSelectDrawer(); }}>View User Details</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn">Options
              <i className="fa fa-caret-down"></i>
            </button>
            <div className="dropdown-content">
              <a onClick={() => this.toggleServerLogs()}>{(!this.state.showServerLogs ? "Show" : "Hide") + " Server Logs"}</a>
              <a onClick={() => this.toggleStepDelay()}>{(!this.state.enableStepDelay ? "Slow Down" : "Speed Up") + " Transaction Time"}</a>
            </div>
          </div>
          <a onClick={() => createOverlay("aboutOverlay", About)}>About</a>
        </header>
        <div className="main">
          <div id="userList" className="userList">
            <a className="closebtn" onClick={() => { closeUserSelectDrawer() }}>&times;</a>
            <UserList users={users} setCurrentUser={this.setCurrentUser} />
          </div>
          <div className="transactionBlock">
            {transactionUI}
          </div>
          <div className="menu">
          </div>  
        </div>
        {serverLogsUI}
      </div>
    );
  }
}

function createOverlay(overlayId, typeToRender, props = {}) {
  var aboutOverlay = document.getElementById(overlayId);
  if (!aboutOverlay)
  {
    aboutOverlay = document.createElement("DIV");
    aboutOverlay.id = overlayId;
    ReactDOM.render(React.createElement(typeToRender, props),
      document.body.appendChild(aboutOverlay)
    );
  } else 
  {
    ReactDOM.render(React.createElement(typeToRender, props),
      aboutOverlay
    );
    aboutOverlay.style.display = "block";
  }
  aboutOverlay.onclick = () => { document.getElementById(overlayId).style.display = "none"; }
}

function openUserSelectDrawer() {
  var userList = document.getElementById("userList");
  if (userList)
  {
    userList.style.width = "250px";
  }
}

function closeUserSelectDrawer() {
  var userList = document.getElementById("userList");
  if (userList)
  {
    userList.style.width = "0px";
  }
}

ReactDOM.render(React.createElement(NicksUI),
  document.getElementById('root')
);
