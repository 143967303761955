import React from "react";
import "./IDCard.css";

class IDCard extends React.Component {
    render() {
        const user = this.props.user;
        if (user === undefined)
        {
            return null;
        }
        if (user.fingerprint === undefined)
        {
            user.fingerprint = "";
        }
        const imagePath = "./collateral/images/id" + (user.idNumber % 8) + ".png";
        return (
            <div className="IDCard">
                <img src={imagePath} alt="User" />
                <h2>{user.name}</h2>
                <p><b>Fingerprint:</b> {user.fingerprint.substring(0, 11)}</p>
                <p><b>ID Number:</b> {user.idNumber}</p>
            </div>
        );
    }

}

export default IDCard;